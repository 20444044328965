<template>
	<header class="site-header" :class="$responsive">
		<mobile-menu  v-if="isMobile"></mobile-menu>
		<nav class="site-header__nav" v-else>
			<logo-zionesport class="site-header__logo" @click="goHome"/>
			<ul class="site-header-nav__items">
				<li class="site-header-nav__item">
					<router-link :to="{ name: 'products' }" class="router__link">Productos</router-link>
				</li>
				<li class="site-header-nav__item">
					<router-link :to="{ name: 'company' }" class="router__link">Nosotros</router-link>
				</li>
				<li class="site-header-nav__item">
					<router-link :to="{ name: 'contactUs' }" class="contact-us-btn">Contacto</router-link>
				</li>
			</ul>
		</nav>
	</header>
</template>

<script>
	import logoZionesport from "@/components/LogoZionesportH.vue";
	import mobileMenu from "@/components/MobileMenu.vue";

	export default {
		name: "HeaderNav",
		components: {
			logoZionesport,
			mobileMenu
		},
		computed: {
			isMobile() {
				return this.$responsive.mobile;
			} 
		},
		methods: {
			goHome() {
				return this.$router.push('/');
			}
		}
	}
</script>

<style scoped>

.site-header {	
	/* position: -webkit-sticky; /* Safari */
 	/* position: sticky;*/
 	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 101;
	background-color: rgba(255, 255, 255, .33);
	
}

.site-header.desktop {
	backdrop-filter: blur(var(--header-blur));
}

.site-header__nav {
	display: flex;
	width: 100vw;
	height: 80px;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
}

.site-header__logo {
	height: 60%;
	cursor: pointer;
}

.site-header-nav__items {
	display: flex;
	align-items: center;
}

.site-header-nav__item {
	padding: 0 2rem;
	color: var(--secondary-color);
	font-weight: 600;
}

.router__link {
	text-decoration: none;
	color: var(--secondary-color);
}

.router__link:hover {
	color: var(--primary-color);
}

.router-link-active {
	color: var(--primary-color);
}


@media screen and (max-width:640px) {
	.site-header {
  	background-color: rgba(255,255,255,0.97);
	}
}
</style>