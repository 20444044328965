<template>
	<div class="clients-section" :class="$responsive">
		<div class="page-section">
			<h2 class="clients__title" v-markdown:title>
				<slot name="title"></slot>
			</h2>
			<div class="description" v-markdown:text>
					<slot name="description"></slot>
			</div>
			<div class="top-clients">
				<div class="clients__item" v-for="(logo, index) in topClients" :key="index">
					<img :src="require(`../assets/${logo}`)"/>
				</div>
			</div>
			<p class="clients-subtitle" v-markdown:text>
				<slot name="subtitle"></slot>
			</p>
			<client-slider :items="regularClients"/>
		</div>	
	</div>
</template>

<script>
	import content from "@/content.json";
	import clientSlider from "@/components/ClientSlider";

	export default {
		name: "OurClients",
		components: {
			clientSlider
		},
		data() {
			return {
				clients: content.collections.clients
			}
		},
		computed: {
			topClients() {
				const topFive = this.clients.slice(0, 5);
				const randomTopClient = this.shuffle[0];
				return [ ...topFive, randomTopClient ];
			},
			regularClients() {
				return this.shuffle.slice(1);
			},
			shuffle() {
				const shuffled = this.clients.slice(5);
				for (let i = shuffled.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
				}
				return shuffled;
			},
		},
	}
</script>

<style scoped>

.clients__title {
	font-size: 2.75rem;
	line-height: 1.125em;
	letter-spacing: -0.066rem;		
}

.top-clients {
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	justify-content: space-evenly;
	--border-color: #d7d6d4;
	--item-width: 16.66%;
	--item-height: 130px;
	--item-padding: .75rem;
	--item-img-width: 95%;
	--item-img-height: 3.5rem;
}

.sm-view .top-clients {
	width: 100%;
	flex-wrap: wrap;
	--item-width: 33%;
	--item-height: 80px;
	--item-padding: 0;
	--item-img-width: 86%;
	--item-img-height: 2.5rem;
}

.clients__item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--item-padding);
	width: var(--item-width);
	min-height: var(--item-height);
	flex-shrink: 0; /* Para que no se encojan */
}

.clients__item > img {
	max-width: var(--item-img-width);
	max-height: var(--item-img-height);
}

.clients-subtitle {
	color: var(--secondary-color);
}

</style>