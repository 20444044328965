<template>
	<div class="children-handler">
		<text-box 
			v-if="children.type == 'textbox'"
			:textbox="children">
		</text-box>
		<image-box 
			v-if="children.type == 'imagebox'"
			:imagebox="children">
		</image-box>
		<base-link
			v-if="children.type == 'baselink'"
			:link="children">
		</base-link>
		<getStartedBtn
			v-if="children.type == 'getstartedbtn'">
		</getStartedBtn>
		<base-paragraph
			v-if="children.type == 'paragraph'"
			:paragraph="children">
		</base-paragraph>
		<image-mock 
			v-if="children.type == 'mockup'"
			:media="children">
		</image-mock>
		<base-button
			v-if="children.type == 'basebutton'"
			:options="children">
		</base-button>
		<base-quote
			v-if="children.type == 'quote'"
			:author="children.author"
			:photo="children.authorPhoto">
			{{ children.content }}
		</base-quote>
		<client-list
			v-if="children.type == 'clientlist'">
			{{ children.content }}
		</client-list>
	</div>
</template>

<script>
	import textBox from "@/components/TextBox.vue";
	import imageBox from "@/components/ImageBox.vue";
	import baseLink from "@/components/BaseLink.vue";
	import getStartedBtn from "@/components/GetStartedBtn.vue";
	import baseParagraph from "@/components/BaseParagraph.vue";
	import imageMock from "@/components/ImageMock.vue";
	import baseButton from "@/components/BaseButton.vue";
	import baseQuote from "@/components/BaseQuote.vue";
	import clientList from "@/components/ClientList.vue";

	export default {
		props: {
			children: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			textBox,
			imageBox,
			baseLink,
			getStartedBtn,
			baseParagraph,
			imageMock,
			baseButton,
			baseQuote,
			clientList,
		}
	}
</script>