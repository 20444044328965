<template>
	<div class="client-list" :class="$responsive">
		<div class="client-list__logo" v-for="(logo, index) in clients" :key="index">
			<img :src="require(`../assets/${logo}`)"/>
		</div>
	</div>
	<p class="client-list__footer">
		<slot></slot>
	</p>
</template>

<script>
	import content from "@/content.json";

	export default {
		name: "ClientList",
		data() {
			return {
				clients: content.collections.clients
			}
		}
	}
</script>

<style scoped>

.client-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	justify-content: space-evenly;
	--border-color: #d7d6d4;
	--item-width: 16.66%;
	--item-height: 68px;
	--item-padding: .6rem;
	--item-img-width: 95%;
	--item-img-height: 2rem;
}

.sm-view .client-list {
	margin-top: 2.5rem;
	--item-width: 33%;
	--item-height: 64px;
	--item-img-width: 86%;
}

.client-list__logo {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--item-padding);
	width: var(--item-width);
	min-height: var(--item-height);
	aspect-ratio: 1.618 / 1a;
}

.sm-view .client-list__logo:not(:nth-child(3n)) {
	border-right: 1px dashed #e7e6e4;
}

.desktop .client-list__logo:not(:nth-child(6n)) {
	border-right: 1px dashed #e7e6e4;
}

.client-list__logo > img {
	max-width: var(--item-img-width);
	max-height: var(--item-img-height);
}

.client-list__footer {
	width: 60%;
	text-align: center;
	color: var(--secondary-color);
	font-size: 1rem;
	margin: 1rem auto;
}

.mobile .client-list__footer {
	width: 100%;
}
</style>