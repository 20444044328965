<template>
	<div class="section-handler">
		<div v-for="(section, sectionIndex) in sections" :key="sectionIndex">

			<default-section 
				v-if="section.type == 'default'"
				:section="section"
				:id="section.id">
			</default-section>

			<single-section 
				v-if="section.type == 'single'"
				:section="section"
				:id="section.id">
			</single-section>

			<columns-section 
				v-if="section.type == 'columns'"
				:section="section"
				:id="section.id">
			</columns-section>

			<sports
				v-if="section.type == 'sports'"
				:section="section"
				:id="section.id">
			</sports>

			<get-started-steps
				v-if="section.type == 'getStartedSteps'"
				:section="section"
				:id="section.id">
			</get-started-steps>

			<our-clients v-if="section.type == 'ourClients'">
				<template #title>{{ section.title }}</template>
				<template #description>{{ section.description }}</template>
				<template #subtitle>{{ section.subtitle }}</template>
			</our-clients>

			<next-section
				v-if="section.type == 'nextSection'" 
				:section="section">
			</next-section>

			<content-section
				v-if="section.type == 'content'" 
				:section="section"
				:id="section.id">
			</content-section>

			<tech-stack
				v-if="section.type == 'techStack'" 
				:section="section"
				:id="section.id">
			</tech-stack>

			<product-section
				v-if="section.type == 'product'" 
				:section="section"
				:id="section.id">
			</product-section>

			<quote-section
				v-if="section.type == 'quote'" 
				:section="section"
				:id="section.id">
			</quote-section>

			<plans-section
				v-if="section.type == 'plans'"
				:section="section"
				:id="section.id">
			</plans-section>
			
		</div>
	</div>	
</template>

<script>
	import defaultSection from "@/components/DefaultSection.vue";
	import singleSection from "@/components/SingleSection.vue";
	import columnsSection from "@/components/ColumnsSection.vue";
	import sports from "@/components/Sports.vue";
	import getStartedSteps from "@/components/GetStartedSteps.vue";
	import ourClients from "@/components/OurClients.vue";
	import nextSection from "@/components/NextSection.vue";
	import contentSection from "@/components/ContentSection.vue";
	import techStack from "@/components/TechStack.vue";
	import productSection from "@/components/ProductSection.vue";
	import quoteSection from "@/components/QuoteSection.vue";
	import plansSection from "@/components/PlansSection.vue";
	
	export default {
		props: {
			sections: {
				type: Array,
				default: () => []
			}
		},
		components: {
			defaultSection,
			singleSection,
			columnsSection,
			sports,
			getStartedSteps,
			ourClients,
			nextSection,
			contentSection,
			techStack,
			productSection,
			quoteSection,
			plansSection,
		}
	}
</script>