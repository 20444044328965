<template>
	<div class="page-view-index">
		<view-navigation :items="navItems"></view-navigation>
		<router-view v-slot="{ Component }">
			<transition :name="transitionName">
				<component :is="Component"/>
			</transition>
		</router-view>
	</div>
</template>

<script>
	import content from "@/content.json";
	import viewNavigation from "@/components/ViewNavigation"

	export default {
		name: "PageIndex",
		props: ['page'],
		components: {
			viewNavigation
		},
		data() {
			return {
				navItems: content.pageNavs[this.page],
				transitionName: 'slide'
			}
		},
		watch: {
			'$route' (to, from) {
				const navIndex = route => this.navItems.findIndex(e => e.routeName === route.name);
				this.transitionName = (navIndex(to) > navIndex(from)) ? 'slide' : 'slide-back';
			}
		}
	}
</script>

<style scoped>
	.slide-enter-active {
		animation: snap 0.3s ease-in-out;
		--traslate-x: 80vw;
	}

	.slide-back-enter-active {
		animation: snap 0.3s ease-in-out;
		--traslate-x: -80vw;
	}

	@keyframes snap {
		from {
			transform: translateX(var(--traslate-x));
		}
		to {
			transform: translateX(0);
		}
	}
</style>