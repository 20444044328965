<template>
	<div class="client__slider">
		<div class="slider-arrow" @click="prev">
			<svg width="18" height="18" viewBox="0 0 11 11" fill="none">
				<path class="nav-arrow" d="M9.52311 10.0231 L5.22513 5.72513 L9.45027 1.5" stroke-width="1.5" stroke-linecap="round"></path>
			</svg>
		</div>
		<div class="slider" ref="slider">
			<div class="client__wrapper" :style="sliderStyle">
				<div class="client__item" v-for="(logo, index) in items" :key="index">
					<img :src="require(`../assets/${logo}`)"/>
				</div>
			</div>	
		</div>
		<div class="slider-arrow" @click="next">
			<svg width="18" height="18" viewBox="0 0 11 11" fill="none">
				<path class="nav-arrow" d="M5.22513 10.0231L9.52311 5.72513L5.29797 1.5" stroke-width="1.5" stroke-linecap="round"></path>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			currentIndex: 0,
			slideWidth: 0,
			gap: 0,
			timer: null
		}
	},
	computed: {
		slidesToShow() {
      return this.$responsive.mobile ? 3 : 6;
    },
		sliderStyle() {
			const totalWidthPerSlide = this.slideWidth + this.gap;
			const translateX = -(this.currentIndex * totalWidthPerSlide);
			return {
				transform: `translateX(${translateX}px)`
			}
		},
	},
	mounted() {
		this.startTimer();
	},
	beforeUnmount() {
		clearTimeout(this.timer);
	},
	methods: {
		next() {
			this.slideWidth = this.$refs.slider.offsetWidth;
			if (this.currentIndex * this.slidesToShow < this.items.length - this.slidesToShow) {
				this.currentIndex++;
			} else {
				this.currentIndex = 0;
			}
			this.startTimer();
		},
		prev() {
			if (this.currentIndex > 0) {
				this.currentIndex--;
				this.startTimer();
			}
		},
		startTimer() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => { this.next(); }, 8000);
		}
	}
}
</script>

<style scoped>

.client__slider {
	width: 90%;
	display: grid;
	grid-template-columns: 1fr 90% 1fr;
	grid-template-rows: auto;
	align-items: center;
	margin: 0 auto;
	--border-color: #d7d6d4;
	--item-width: 16.66%;
	--item-height: 70px;
	--item-padding: .75rem;
	--item-img-width: 95%;
	--item-img-height: 2.25rem;
	--transition-duration: 0.75s;
}

.slider {
	overflow: hidden;
}

.client__wrapper {
	display: flex;
	flex-wrap: nowrap;
	transition: transform var(--transition-duration) ease;
}

.mobile .client__slider {
	width: 100%;
	grid-template-columns: 1fr 80% 1fr;
	--item-width: 33.333%;
	--item-height: 60px;
	--item-padding: 0;
	--item-img-width: 86%;
	--item-img-height: 2.125rem;
	--transition-duration: 0.5s;
}

.client__item {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--item-padding);
	width: var(--item-width);
	min-height: var(--item-height);
	flex-shrink: 0; /* Para que no se encojan */
	filter: grayscale(100%);
	opacity: .6;
}

.client__item > img {
	max-width: var(--item-img-width);
	max-height: var(--item-img-height);
}

.client__slider .clients__item {
	filter: grayscale(100%);
	opacity: .6;
}

.slider-arrow {
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-arrow {
	stroke: var(--primary-color);
}

.slider-timer {
	width: 100%;
	height: 4px;
	background-color: #e6e7e4;
}
</style>