<template>
	<div class="clients-section" :class="classes">
		<div class="clients__description">
			<slot>Ellos ya utilizan <b>zione.sport</b> para administrar sus torneos.</slot>	
		</div>
		<div class="clients__grid">
			<div class="clients__item" v-for="(logo, index) in clients" :key="index">
					<img :src="require(`../assets/${logo}`)"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "OurClientsCompact",
		props: {
			grayscale: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				clients: ['cte-cordica.svg', 'cte-copa-santos-icon.svg', 'cte-scyf.svg', 'cte-copa-mazatlan-2.svg', 'cte-forca-new.svg', 'cte-tdla-2022.svg']
			}
		},
		computed: {
			classes() {
				return { 
					"grayscale": this.grayscale,
					...this.$responsive
				}
			}
		}
	}
</script>

<style scoped>

.clients__description {
	margin: 0 auto;
	font-size: 1rem;
	color: var(--secondary-color);
}

.clients__grid {
	width: 90%;
	margin: 1.25rem auto 0;
	display: flex;
	justify-content: space-around;
}

.clients__item {
	width: 17.5%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.clients__item > img {
	width: 95%;
	max-height: 2.125rem;
}

.mobile .clients__item > img {
	width: 100%;
	max-height: 1.875rem;
}

.clients-section.grayscale .clients__item > img {
	filter: grayscale(100%) opacity(60%);
}
</style>